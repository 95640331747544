/* eslint-disable no-undef */
import { configureStore, createSlice } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';

const initialState = {
  sidebarShow: true,
  darkMode:
    localStorage.getItem('darkMode') !== null
      ? JSON.parse(localStorage.getItem('darkMode'))
      : false,
  alerts: [],
  title: process.env.REACT_APP_APP_TITLE,
  appCodeName: process.env.REACT_APP_APP_CODE_NAME,
  company: {},
};

export const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    set: (state, { payload }) => {
      return { ...state, ...payload };
    },
    pushAlert: (state, { payload }) => {
      state.alerts.push(payload);
    },
    popAlert: (state) => {
      state.alerts.splice(0, 1);
    },
    toggleDarkMode: (state, { payload }) => {
      state.darkMode = payload;
      localStorage.setItem('darkMode', state.darkMode);
    },
    setCompany: (state, { company }) => {
      state.company = company;
    },
  },
});

export const { set, pushAlert, popAlert, toggleDarkMode, setCompany } =
  appSlice.actions;

const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    user: userSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;
